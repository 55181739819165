.logo{
    width: 200px;
}

.nav{
    position: fixed;
    top: 0px;
    padding: 20px;
    z-index: 1;
    width: 100%;
    height: 100px;
}
.nav_black{
    background-color: black;
}