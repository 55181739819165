.movies{
    display:flex;
    overflow-x: scroll;
    overflow-y: hidden;
    
}
.movie{
    width: 100%;
    max-height: 150px;
    padding: 10px;
    transition: 0.5s ease-in-out;
    
}
.row{
    margin-left: 30px;
   
}
.movies::-webkit-scrollbar{
    display:none;
}
.movie:hover{
    transform: scale(1.08);
}

.largeMovie{
    width: 100%;
    max-height: 250px;
    padding: 10px;
    transition: 0.5s ease-in-out;}